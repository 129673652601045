import React, { useRef, useState } from "react"

// import { isEmpty } from "lodash"

import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import Form from "./elements/Form"
import LanguageSelect from "./elements/LanguageSelect"
import PhoneInput from "./elements/PhoneInput"
import Recaptcha from "./elements/Recaptcha"
import TermsConditions from "./elements/TermsConditions"
import TextAreaInput from "./elements/TextAreaInput"
import TextInput from "./elements/TextInput"
import { teamContactSchema } from "./schemas"
import {
  formTracking,
  generateUuid,
  getBitrixData,
  isProduction,
  postFormData,
  sendMail,
  useBlock,
} from "./utils"
import useEmailContent from "@hooks/dataHooks/useEmailContent"
import { useGlobalStore } from "@stores/globalStore"
const TeamContactForm = ({ email, name, submitText }) => {
  const recaptchaRef = useRef()
  const data = useEmailContent()
  const { ipAddress, setgaEmail, setShowSuccessMsg } = useGlobalStore()
  const formConfig = FORM_CONFIG.teamContact
  let emailContentTemp = data?.filter((item) => item.choose_template ===  formConfig?.emailTempUser);
  const emailContent = emailContentTemp[0]?.content?.data?.content;
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data) => {
    setIsLoading(true); // Start loading

    const ipList = await useBlock();
    const isBlocked = ipList?.ip?.some(item => item?.add_ip_address === ipAddress);
    const isBlocked1 = ipList?.email?.some(item => item?.email === data.email);
    setgaEmail(data?.email)
   
    const token = await recaptchaRef.current?.executeAsync()
    if (token) {
      const unique_id = generateUuid()
      const postData = {
        first_name: data.name?.split(" ")[0],
        contactName: name,
        contactEmail: email,
        ...data,
        ...formConfig,
        unique_id: unique_id,
        "g-recaptcha-response": token,
        extra: { formConfig, data },
      }
      const bitrixData = getBitrixData(postData)

      const formData = new FormData()

      formData.append("data", JSON.stringify(bitrixData))

      recaptchaRef.current?.reset()
      if(isBlocked || isBlocked1){
        setIsLoading(false); // End loading
        return;
      }
      else {
        try {
          setTimeout(() => {
            setShowSuccessMsg(true)
          }, 1000)
          postFormData(formData);
          sendMail(bitrixData);
          formTracking({
            unique_id: unique_id,
            eventTracking: formConfig.eventTracking,
            formName: formConfig.form_name,
            email: data?.email,
            phone: data?.telephone
          })
        } catch (error) {
          setIsLoading(false); // End loading
          console.error("Error submitting form:", error);
        } finally {
          setIsLoading(false); // End loading
        }
     
    }
  }
  }

  return (
    <Form
      className="team-contact-form"
      validationSchema={teamContactSchema}
      onSubmit={onSubmit}
      successMsg={SUCCESS_MSG}
    >
      <div className="form-grid">
        <TextInput name="name" placeholder="Name" label="Name" />
        <TextInput
          name="email"
          placeholder="Email"
          type="email"
          label="Email"
        />
        <PhoneInput name="telephone" label="Phone" />
        <LanguageSelect />
        <TextAreaInput
          name="message"
          placeholder="Share any additional information"
          label="Message"
        />
        <div className="d-none"><TextInput name="emailContent" type="hidden" value={emailContent} /></div>
      </div>
      <div className="form-bottom">
        <button className="button button-orange" type="submit">
          <span>{isLoading ? "Loading..." : submitText || "Submit Details"}</span>
        </button>
        <TermsConditions />
      </div>
      <Recaptcha recaptchaRef={recaptchaRef} />
    </Form>
  )
}

export default TeamContactForm
